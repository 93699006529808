import React from "react"
import H2Title from "../../blocks/h2-title"
import Img from "gatsby-image"

export default (props) => (
    <section className="more" id={`more-${props.id}`}>
        <div className="more-img-right"
             style={{background: `url(${props.data.bgImage.publicURL})`, backgroundSize: 'contain'}}>
            <div className="more__header">
                <H2Title h2={props.data.title}/>
            </div>
            <div className="more__img">
                <Img fluid={props.data.img.childImageSharp.fluid} alt="Софтиндустрия"/>
            </div>
            <div className="more__footer">
                <div className="more__text" dangerouslySetInnerHTML={{__html: props.data.textHTML}}>
                </div>
            </div>
        </div>
    </section>
)
