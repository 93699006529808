import React from "react"
import {Link} from "gatsby"
import Crumb from "./crumb";
import Helmet from "react-helmet"

const Breadcrumbs = (props) => {
    let crumbs = props.breadcrumbs.split("/").filter(el => el !== "");
    let crumbsUrl = props.pageUrl.split("/").filter(el => el !== "");
    let itemListElements = [{
        "@type": "ListItem",
        "position": 1,
        "name": "Главная",
        "item": "https://softindustry.by"
    }];
    crumbs.map((el, index) => {
        itemListElements.push({
            "@type": "ListItem",
            "position": 1,
            "name": el,
            "item": 'https://softindustry.by/' + crumbsUrl.slice(0, index + 1).join('/')
        })
    });
    let schemaOrgBreadcrumb = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": itemListElements
    };
    let lastCrumb = crumbs.pop();
    let crumbsUrls = crumbsUrl.slice(0, -1);

    return (
        <div className="row">
            <div className="breadcrumbs col">
                <p className="breadcrumbs__line">
                    <Link to="/" className="breadcrumbs__element">
                        Главная{" "}
                    </Link>{" "}🡢{" "}
                    {crumbs.map((el, index) => <Crumb key={index}
                                                      link={'/' + crumbsUrls.slice(0, index + 1).join('/')}
                                                      crumb={el}/>)}
                    <span className="breadcrumbs__element breadcrumbs__element_last">
                        {" "}{lastCrumb}
                    </span>
                </p>
            </div>
            <Helmet script={[
                {
                    "type": "application/ld+json",
                    innerHTML: `${JSON.stringify(schemaOrgBreadcrumb)}`
                }
            ]}/>
        </div>
    )
}

export default Breadcrumbs