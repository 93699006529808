import React from "react"
import FormInput from "./form-input";


class FormSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valueName0: '',
            valueName1: '',
            valueName2: '',
            valueName3: '',
            valueName4: '7kP8459f21R8',
            sent: 'not',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        (event.target.name === 'name-input-0') ? this.setState({valueName0: event.target.value}) :
            (event.target.name === 'name-input-1') ? this.setState({valueName1: event.target.value}) :
                (event.target.name === 'name-input-2') ? this.setState({valueName2: event.target.value}) :
                    (event.target.name === 'name-input-3') ? this.setState({valueName3: event.target.value}) :
                        (event.target.name === 'name-input-4') ? this.setState({valueName4: event.target.value}) : this.setState({});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.valueName0 !== '' && this.state.valueName3 === '' && this.state.valueName4 === '7kP8459f21R8') {
            let dataForm = JSON.stringify({
                formData: {
                    "value0": this.state.valueName0,
                    "value1": this.state.valueName1,
                    "value2": this.state.valueName2,
                    "value3": this.state.valueName3,
                    "value4": this.state.valueName4,
                }
            });

            fetch('/main-form-handler.php', {
                method: "POST",
                headers: {
                    "Content-type": "application/json;charset=utf-8"
                },
                body: dataForm
            })
                .then(response => {
                    response.text()
                })
                .then(data => {
                    console.log("Successful " + data);
                })
                .catch(error => {
                    console.log("error " + error);
                });

            this.setState({
                valueName0: '',
                valueName1: '',
                valueName2: '',
                valueName3: '',
                valueName4: '7kP8459f21R8',
                sent: 'sent'
            });
        } else {
            this.setState({
                sent: 'eff-form'
            });
        }
    }

    render() {
        return (
            <section className="form-section container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2 className="form-section__title">
                                {this.props.data.title}
                            </h2>
                            <p className="form-section__subtitle">
                                {this.props.data.subtitle}
                            </p>
                        </div>
                    </div>
                    <form className="form-section__form" onSubmit={this.handleSubmit}>
                        <div className="row">

                            <div className="col-md-3">
                                <div className="form-section__input-bg">
                                    <input
                                        className="form-section__input"
                                        type={this.props.data.inputs[0].type}
                                        name="name-input-0"
                                        placeholder={this.props.data.inputs[0].placeholder}
                                        required={this.props.data.inputs[0].required}
                                        value={this.state.valueName0}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-section__notice"/>
                            </div>

                            <div className="col-md-3">
                                <div className="form-section__input-bg">
                                    <input
                                        className="form-section__input"
                                        type={this.props.data.inputs[1].type}
                                        name="name-input-1"
                                        placeholder={this.props.data.inputs[1].placeholder}
                                        required={this.props.data.inputs[1].required}
                                        value={this.state.valueName1}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-section__notice"/>
                            </div>

                            <div className="col-md-3">
                                <div className="form-section__input-bg">
                                    <input
                                        className="form-section__input"
                                        type={this.props.data.inputs[2].type}
                                        name="name-input-2"
                                        placeholder={this.props.data.inputs[2].placeholder}
                                        required={this.props.data.inputs[2].required}
                                        value={this.state.valueName2}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-section__notice"/>
                            </div>
                            <div className="d-none">
                                <div className="form-section__input-bg">
                                    <input
                                        className="form-section__input"
                                        type="text"
                                        name="name-input-8"
                                        value={this.state.valueName3}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="d-none">
                                <div className="form-section__input-bg">
                                    <input
                                        className="form-section__input"
                                        type="text"
                                        name="name-input-9"
                                        value={this.state.valueName4}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col">
                                {(this.state.sent === 'sent') ?
                                    <p style={{color: 'white'}}>Ваше сообщение успешно отправлено</p> :
                                    (this.state.sent === 'eff-form') ?
                                        <p style={{color: 'white'}}>Не заполнена форма</p> :
                                        <button type="submit" className="button">{this.props.data.button}</button>}
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}

export default FormSection