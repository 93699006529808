import React from "react"
import H2Title from "../../blocks/h2-title";
import AboutBlock from "./about-block";

const AboutSection = (props) => (
        <section className="about container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <H2Title h2={props.data.title}/>
                        <p className="about__subtitle">
                           {props.data.subtitle}
                        </p>
                    </div>
                </div>
                <div className="row">
                    {props.data.blocks.map( (el, index) => <AboutBlock key={index} data={el}/>)}
                </div>
                <div className="row">
                    <div className="col">
                        <div className="title">
                            <div className="title__line" />
                            <h2>{props.data.h2}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="about__desc col-md-6" dangerouslySetInnerHTML={{__html: props.data.text}}>
                    </div>
                    <div className="col-md-6"/>
                </div>
            </div>
        </section>
)

export default AboutSection