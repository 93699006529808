import React from "react"
import {Link} from "gatsby"

const Crumb = (props) => {
    return (
        <span>
            <Link to={props.link} className="breadcrumbs__element">
                {" "}{props.crumb}{" "}
            </Link>
            {" "}🡢{" "}
        </span>
    );
}

export default Crumb