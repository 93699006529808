import React from "react"
import { Link } from "gatsby"
import PortfolioBlock from "./portfolio-block"
import PortfolioLinkToAll from "./portfolio-link-to-all";

const PortfolioSection = (props) => (
    <section className="portfolio container-fluid">
        <div className="row">
            {props.data.portfolioBlocks.map(el => <PortfolioBlock key={el.id} data={el}/>)}
            {props.data.linkToAll === true ? <PortfolioLinkToAll/> : null}
        </div>
    </section>
)

export default PortfolioSection