import React from "react"

const PrinciplesItem = (props) => (
    <div className="principles__item col-md-4 ">
        <div className="principles__item-title">
            <img src={props.data.icon.publicURL} alt="icon" />
            <h4>{props.data.h4}</h4>
        </div>
        <p className="principles__item-text">
            {props.data.text}
        </p>
    </div>
);

export default PrinciplesItem