import React from "react"
import H2Title from "../../blocks/h2-title";
import ShemaBlock from "./shema-block";


const ShemaSection = (props) => (
    <section className="shema">
        <div className="container"><H2Title h2={props.data.title}/>
            <div className="shema__wrapper">
                <div className="shema__top-row">
                    <div className="shema__blocks">
                        {props.data.rowTop.map((el, index) => <ShemaBlock key={index} data={el}/>)}
                    </div>
                </div>
                <div className="shema__bottom-row">
                    <div className="shema__blocks">
                        {props.data.rowBottom.map((el, index) => <ShemaBlock key={index} data={el}/>)}
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default ShemaSection