import React from "react"

const SliderHeader = (props) => (
    <div className="slider__header"
         id={`slider__header_id-${props.index}`}>
        <h1>{props.h1}</h1>
        <p>{props.p}</p>
    </div>
)

export default SliderHeader