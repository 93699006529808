import React from "react"

const TariffsColumn = (props) => (
    <div className={props.check ? "tariffs__column-checked tariffs__column tariffs__column_" + props.columns : "tariffs__column tariffs__column_" + props.columns}
         onMouseEnter={(e) => props.itemHover(e, props.id)}
    >
        <h3 className="tariffs__column_title">
            {props.data.title}
        </h3>
        <p className="tariffs__column_price">
            <span>от </span> {props.data.price}<span> руб</span>
        </p>
        {props.data.list.map((el, index) => (el.checked) ?
            <p key={index} className="tariffs__column_item tariffs__column_item-check" dangerouslySetInnerHTML={{__html: el.item}}/> :
            <p key={index} className="tariffs__column_item tariffs__column_item-uncheck" dangerouslySetInnerHTML={{__html: el.item}}/>
        )}
        <div className="tariffs__column_button">
            <button className={props.check ? "button button__secondary" : "button"} onClick={props.modalOpen}>{props.data.buttonText}</button>
        </div>
    </div>
)

export default TariffsColumn