import React from "react"
import CalcHelp from "./calc-help";
import CalcInput from "./calc-input";
import CalcInsideRadio from "./calc-inside-radio";

const CalcBlock = (props) => (
    <div className={(props.length === 2) ? 'calculator-block col-md-6' :
        (props.length === 4) ? 'calculator-block col-xl-3 col-md-6' :
            (props.length > 4) ? 'calculator-block col-lg-4 col-md-6' :
                'calculator-block col-md-6'}>
        <input className="input-calculator-block"
               type="checkbox"
               name={props.stepName}
               id={props.data.blockName}
               checked={props.data.checked}
               onChange={() => props.blockChecked(props.stepId, props.id)}
        />
        <label htmlFor={props.data.blockName}>
            <h4>
                {props.data.blockTitle}
            </h4>
            {(props.data.blockPrice) ?
                <p className="block-price">от <span>{props.data.blockPrice}</span> Руб.</p> : ''}
            <p className="inside-block_desc">
                {props.data.blockDescription}
            </p>
            {(props.data.blockInput) ?
                <CalcInput value={props.data.blockInput.value}
                           name={props.data.blockName}
                           stepID={props.stepId}
                           blockID={props.id}
                           description={props.data.blockInput.description}
                           handleInputChange={props.handleInputChange}/> : ''}
            {(props.data.help) ?
                <CalcHelp help={props.data.help}/> : ''}
            {(props.data.radioBlocks) ?
                <CalcInsideRadio name={props.data.blockName}
                                 data={props.data.radioBlocks}
                                 stepID={props.stepId}
                                 blockID={props.id}
                                 insideRadioChecked={props.insideRadioChecked}/> : ''}
        </label>
    </div>
)

export default CalcBlock