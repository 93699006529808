import React from "react"
import bg1 from "../../../data/images/portfolio/image-corp.jpg"

const ServicePortfolioBlock = (props) => (
    <div className="col-md-3">
        <div
            className="service__portfolio-elem"
            style={{
                background: `url("${(props.bgImage) ? props.bgImage.publicURL : bg1}")`,
                backgroundSize: "cover"
            }}
        >
            <div className="service__portfolio-content">
                <h3 className="service__portfolio-h3">{props.h3}</h3>
                <p className="service__portfolio-title">
                    {props.title}
                </p>
                <p className="service__portfolio-desc">{props.desc}</p>
                <img
                    src={props.logo.publicURL}
                    alt="logo"
                    className="service__portfolio-logo"
                />
            </div>
        </div>
    </div>
);

export default ServicePortfolioBlock