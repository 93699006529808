import React from "react"
import {YMaps, Map, Placemark} from 'react-yandex-maps';

const MapSection = () => (
    <section className="map" id={`map-1`}>
        <div className="container-fluid">
            <div className="row">
                <YMaps query={{ load: 'control.ZoomControl' }}>
                    <Map defaultState={{center: [53.867260, 27.508886], controls: ['zoomControl'], behaviors: ['drag', 'dblClickZoom', 'multiTouch', 'rightMouseButtonMagnifier'], zoom: 15}}
                         style={{width: '100%', height: '400px'}}>
                        <Placemark geometry={[53.867260, 27.508886]} properties={{
                            hintContent: "г.Минск, пер.Софьи Ковалевской, 60, офис 703",
                            iconCaption: 'ООО "СофтИндустрия"'
                        }} modules={['geoObject.addon.hint']}/>
                    </Map>
                </YMaps>
            </div>
        </div>
    </section>
)

export default MapSection