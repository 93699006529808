import React from "react"
import CalcInsideRadioItem from "./calc-inside-radio-item";

const CalcInsideRadio = (props) => (
    <div className="inside-block">
        {props.data.map((el, index) =>
            <CalcInsideRadioItem key={index}
                                 id={index}
                                 data={el}
                                 name={props.name}
                                 stepID={props.stepID}
                                 blockID={props.blockID}
                                 insideRadioChecked={props.insideRadioChecked}/>)}
    </div>
)
export default CalcInsideRadio