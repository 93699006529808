import React from "react"
import { Link } from "gatsby"

class TagsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: false };
    }
    handleToggle() {
        let tagContainer = document.querySelector('.tags-section__tags');
        if (tagContainer.classList.contains('tags-section__tags-expand')) {
            tagContainer.classList.remove('tags-section__tags-expand');
        } else {
            tagContainer.classList.add('tags-section__tags-expand');
        }
    }
    componentDidMount() {
        document.querySelector('.tags-section__toggle').addEventListener('click', this.handleToggle)
    }
    componentWillUnmount() {
        document.querySelector('.tags-section__toggle').removeEventListener('click', this.handleToggle)
    }
    render() {
        return (
            <section className="tags-section container-fluid">
                <div className="tags-section__wrapper container">
                    <div className="row">
                        <div className="tags-section__tags col">
                            {this.props.data.tags.map((el, index) => <Link key={index} to={el.link} className="tags-section__tag">{el.tag}</Link>)}
                            <div className="tags-section__gradient" />
                        </div>
                    </div>
                </div>
                <div className="tags-section__footer container">
                    <div className="row">
                        <div className="tags-section__toggle col">
                            <p>Показать все</p>
                            <div className="tags-section__arrow">
                                <div className="tags-section__arrow-end" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default TagsSection