import React from "react"
import {Link} from "gatsby";

const SubmenuBlock = (props) => (
    <div className="col-md-4">
        <Link to={props.link} className="submenu-section__block">
            <img src={props.icon.publicURL} alt="icon"/>
            <div>
                <h4>{props.h4}</h4>
            </div>
        </Link>
    </div>
)

export default SubmenuBlock