import React from "react"
import H2Title from "../../blocks/h2-title";
import PrinciplesItem from "./principles-item";

const PrinciplesSection = (props) => (
    <section className="principles container-fluid">
        <div className=" container">
            <div className="row">
                <div className="col">
                    <H2Title h2={props.data.title}/>
                </div>
            </div>
            <div className="row">
                {props.data.cols.map(el => <PrinciplesItem key={el.id} data={el}/>)}
            </div>
        </div>
    </section>
)

export default PrinciplesSection