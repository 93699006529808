import React from "react"
import CalcBlock from "./calc-block"

const CalcStep = (props) => (
    <div className="step" style={{background: (props.id % 2) ? 'white' : 'transparent'}}>
        <div className="step-title">
            <img src={props.data.stepImg.publicURL} alt={props.data.stepTitle}/>
            <h3>
                {props.data.stepTitle}
            </h3>
            <p>
                {props.data.stepDesc}
            </p>
        </div>
        <div className={props.data.classlist || 'step-blocks row'}>
            {props.data.stepBlocks.map((el, index) =>
                <CalcBlock key={index}
                           id={index}
                           stepId={props.id}
                           stepType={props.data.stepType}
                           data={el}
                           length={props.data.stepBlocks.length}
                           stepName={props.data.stepName}
                           blockChecked={props.blockChecked}
                           insideRadioChecked={props.insideRadioChecked}
                           handleInputChange={props.handleInputChange}/>)}
        </div>
    </div>
)

export default CalcStep