import React from "react"

const CalcInsideRadioItem = (props) => (
    <div className='inside-desc'>
        <input type="radio"
               value={props.data.radioPrice || 0}
               id={`${props.name}-radio-${props.id}`}
               name={`${props.name}-radio`}
               checked={props.data.checked}
               onChange={() => props.insideRadioChecked(props.stepID, props.blockID, props.id, true)}/>

        <label
            htmlFor={`${props.name}-radio-${props.id}`}
            dangerouslySetInnerHTML={{__html: props.data.radioDesc}}/>
        {props.data.radioHelp ?
            <div className="block-help" dangerouslySetInnerHTML={{__html: props.data.radioHelp}}/> : ''}
    </div>
)
export default CalcInsideRadioItem