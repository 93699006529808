import React from "react"

const ShemaBlock = (props) => (
    <div className="shema__block">
        <div className="shema__block_border">
            <img src={props.data.icon.publicURL} alt="icon"/>
        </div>
        <p>{props.data.text}</p>
    </div>
)

export default ShemaBlock