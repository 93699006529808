import React from "react"
import { Link } from "gatsby"

const PortfolioBlock = (props) => (
    <div
        className="portfolio__block col-lg-3 col-sm-6 col-xs-12"
        style={{
            background: `url("${props.data.bgImage.publicURL}")`,
            backgroundSize: "cover"
        }}
    >
        <div className="portfolio__block_content">
            <h3 className="portfolio__block_h3">{props.data.h3}</h3>
            <p className="portfolio__block_title">{props.data.title}</p>
            <p className="portfolio__block_desc">{props.data.desc}</p>
            <img
                src={props.data.logo.publicURL}
                alt="logo"
                className="portfolio__block_logo"
            />
            {/*<Link to={props.data.link} className="button">*/}
            {/*    Подробнее*/}
            {/*</Link>*/}
        </div>
    </div>
)

export default PortfolioBlock