import React from "react"
import H2Title from "../../blocks/h2-title";
import Img from "gatsby-image";


const ContentSection = (props) => (
    <section className={props.data.className ? `content-section ${props.data.className}` : 'content-section container-fluid'}>
        {props.data.title ? (<div className="row">
            <div className="col">
                <H2Title h2={props.data.title}/>
            </div>
        </div>) : ''}
        <div className="row">
            <div className="col">
                <div className="content-section__html" >
                    {props.data.img ?
                        <Img fluid={props.data.img.childImageSharp.fluid} alt={props.data.title} style={{width: "50%", float: props.data.imageFloat}}/> : ''}
                    <span dangerouslySetInnerHTML={{__html: props.data.textHTML}}/>
                </div>
            </div>
        </div>
    </section>
)

export default ContentSection