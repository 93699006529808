import React from "react";
import {graphql} from "gatsby";
import Slider from "../components/sections/slider-section/slider-section";
import SubmenuSection from "../components/sections/submenu-section/submenu-section";
import ServiceSection from "../components/sections/service-section/service-section";
import FaqSection from "../components/sections/faq-section/faq-section";
import StackSection from "../components/sections/stack-section/stack-section";
import CalcSection from "../components/sections/calc-section/calc-section";
import MoreSection from "../components/sections/more-section/more-section";
import MapSection from "../components/sections/map-section/map-section";
import FormSection from "../components/sections/form-section/form-section";
import AboutSection from "../components/sections/about-section/about-section";
import Portfolio from "../components/sections/portfolio-section/portfolio-section";
import PrinciplesSection from "../components/sections/principles-section/principles-section";
import ContentSection from "../components/sections/content-section/content-section";
import NewsSection from "../components/sections/news-section/news-section";
import TagsSection from "../components/sections/tags-section/tags-section";
import TariffsSection from "../components/sections/tariffs-section/tariffs-section";
import ReviewSection from "../components/sections/review-section/review-section";
import Layout from "../components/layout";
import ShemaSection from "../components/sections/shema-section/shema-section";

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.pages = props.data.dataJson.pages;
        this.pageData = this.pages.filter(el => el.pageUrl === props.pageContext.pageUrl)[0];
        this.state = {
            modalDisplay: false,
            content: <p>test</p>,
            buttonColor: 'black'
        };
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this)
    }

    modalOpen(content, buttonColor) {
        {console.log(content, buttonColor)}
        this.setState({
            modalDisplay: true,
            content: content,
            buttonColor: buttonColor || 'black'
        })
    }

    modalClose() {
        this.setState({modalDisplay: false})
    }

    render() {
        return (
            <>
                <Layout pageUrl={this.pageData.pageUrl}
                        title={this.pageData.pageTitle}
                        description={this.pageData.description}
                        modalOpen={this.state.modalDisplay}
                        modalClose={this.modalClose}
                        content={this.state.content}
                        buttonColor={this.state.buttonColor}>
                    {this.pageData.sections.map((el, index) =>
                    el.name === 'slider' ? <Slider data={el} key={index}/> :
                    el.name === 'about' ? <AboutSection data={el} key={index}/> :
                    el.name === 'submenu' ? <SubmenuSection key={index}
                                                            pageUrl={this.pageData.pageUrl}
                                                            breadcrumbs={this.pageData.breadcrumbs}
                                                            pageTitle={this.pageData.pageTitle}
                                                            submenuContent={el}/> :
                    el.name === 'portfolio' ? <Portfolio data={el} key={index}/> :
                    el.name === 'more' ? <MoreSection data={el} id={index} key={index}/> :
                    el.name === 'content' ? <ContentSection data={el} key={index}/> :
                    el.name === 'map' ? <MapSection key={index}/> :
                    el.name === 'principles' ? <PrinciplesSection data={el} key={index}/> :
                    el.name === 'form' ? <FormSection data={el} key={index}/> :
                    el.name === 'service' ? <ServiceSection data={el} key={index}/> :
                    el.name === 'faq' ? <FaqSection data={el} key={index}/> :
                    el.name === 'stack' ? <StackSection data={el} key={index}/> :
                    el.name === 'calc' ? <CalcSection data={el} key={index}/> :
                    el.name === 'tariffs' ? <TariffsSection data={el} key={index} modalOpen={this.modalOpen}/> :
                    el.name === 'reviews' ? <ReviewSection data={el} key={index} modalOpen={this.modalOpen}/> :
                    el.name === 'shema' ? <ShemaSection data={el} key={index}/> :
                    //el.name === 'news' ? <NewsSection data={el} key={index}/> :
                    //el.name === 'tags' ? <TagsSection data={el} key={index}/> :
                     ''
                    )}
                </Layout>
            </>
        )
    }
}

export default Page;


export const query = graphql`
query {
  dataJson {
    pages {
      sections {
        name
        id
        title
        button
        buttonText
        buttonURL
        className
        h2
        imageFloat
        bgImage {
          publicURL
        }
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        linkToAll
        map
        price
        promoText
        subtitle
        term
        text
        textHTML
        calcSettings {
          stepDesc
          stepImg {
            publicURL
          }
          stepName
          stepTitle
          stepType
          stepBlocks {
            blockDescription
            blockName
            blockPrice
            blockTitle
            help
            blockInput {
              description
              value
            }
            radioBlocks {
              id
              radioDesc
              radioHelp
              radioPrice
            }
          }
        }
        cols {
          h4
          id
          text
          icon {
            publicURL
          }
        }
        containers {
          h3
          id
          text
        }
        inputs {
          placeholder
          required
          type
        }
        items {
          id
          img1 {
            publicURL
          }
          img2 {
            publicURL
          }
        }
        sliderHeaderData {
          h1
          id
          p
        }
        sliderImages {
          publicURL
        }
        tags {
          link
          tag
        }
        portfolio {
          bgImage {
            publicURL
          }
          desc
          h3
          id
          logo {
            publicURL
          }
          title
        }
        blocks {
          h4
          link
          text
          icon {
            publicURL
          }
        }
        portfolioBlocks {
          desc
          h3
          id
          link
          title
          bgImage {
            publicURL
          }
          logo {
            publicURL
          }
        }
        columns {
          buttonText
          list {
            checked
            item
          }
          checked
          price
          title
        }
        modal {
          button
          subtitle
          title
          inputs {
            placeholder
            required
            type
          }
        }
        rowTop {
          text
          icon {
            publicURL
          }
        } 
        rowBottom {
          text
          icon {
            publicURL
          }
        }
        reviewImages {
          alt
          src {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      breadcrumbs
      pageTitle
      pageUrl
      description
    }
  }
}
`;


