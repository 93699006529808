import React from "react"
import StackCol from "./stack-col";
import Slider from "react-slick";
import H2Title from "../../blocks/h2-title";

class StackSection extends React.Component {
    constructor(props) {
        super(props);
        this.settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 2,
            dotsClass: "pagination",
            appendDots: dots => {
                return (
                    <div className="pagination">
                        <div className="pagination__arrow slider__nav-left" onClick={this.previous}>
                            <div className="pagination__arrow-line">
                                <div className="pagination__tip pagination__tip_left"/>
                            </div>
                        </div>
                        <ul className="pagination__nav">
                            {dots}
                        </ul>
                        <div className="pagination__arrow slider__nav-right" onClick={this.next}>
                            <div className="pagination__arrow-line">
                                <div className="pagination__tip pagination__tip_right"/>
                            </div>
                        </div>
                    </div>
                );
            },
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        return (
            <section className="stack container">
                <H2Title h2={this.props.data.title}/>
                <div className="stack__wrap">
                    <Slider ref={c => (this.slider = c)}  {...this.settings} >
                        {this.props.data.items.map((el, index) => <StackCol key={index} images={el}/>)}
                    </Slider>
                </div>
            </section>
        );
    }
}

export default StackSection