import React from "react"

const AboutBlock = (props) => (
    <div className="col-md-4">
        <div className="about__block">
            <img src={props.data.icon.publicURL} alt="icon" />
            <div>
                <h4>{props.data.h4}</h4>
                <p>{props.data.text}</p>
            </div>
        </div>
    </div>
)

export default AboutBlock