import React from "react"
import { Link } from "gatsby"
import eye from "../../../data/images/eye.png";

const PortfolioLinkToAll = () => (
    <div className="portfolio__block col-lg-3 col-sm-6 col-xs-12">
        <div className="portfolio__block_content portfolio__block_content-more">
            <Link to="/company/portfolio" className="arrow-link">
                <p className="arrow-link__title">Смотреть все работы</p>
                <div className="arrow-link__container">
                    <div className="arrow-link__start"> </div>
                    <img className="arrow-link__icon" src={eye} alt="icon" />
                    <div className="arrow-link__end">
                        <div className="arrow-link__tip" />
                    </div>
                </div>
            </Link>
        </div>
    </div>
)

export default PortfolioLinkToAll