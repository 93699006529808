import React from "react"

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    render() {
        return (
            <div className={this.props.inputClass}>
                <div className="form-section__input-bg">
                    <input
                        className="form-section__input"
                        type={this.props.data.type}
                        name={this.props.data.name}
                        placeholder={this.props.data.placeholder}
                        required={this.props.data.required}
                    />
                </div>
                <div className="form-section__notice" />
            </div>
        );
    }
}

export default FormInput