import React from "react"
import H2Title from "../../blocks/h2-title"
import {Link} from "gatsby"
import Img from "gatsby-image"
import eye from "../../../data/images/eye.png"
import ServicePortfolioBlock from "./service-portfolio-block"


export default (props) => (
    <section className="service" id={`service-${props.data.id}`}>
        <div className={props.data.className}>
            <div className="service__header">
                <H2Title h2={props.data.title}/>
                <p className="service__subtitle">
                    {(props.data.price) ?
                        <span className="service__price">Цена от <strong>
                                    {props.data.price}</strong> руб</span> : ''}
                    {(props.data.term) ?
                        <span className="service__term">Срок создание от <strong>
                                    {props.data.term}</strong> дней</span> : ''}
                </p>
            </div>
            <div className="service__img">
                <Img fluid={props.data.img.childImageSharp.fluid} alt={props.data.title}/>
            </div>
            <div className="service__footer">
                <div className="service__text" dangerouslySetInnerHTML={{__html: props.data.text}}>
                </div>
                <div className="service__buttons">
                    <Link to="/razrabotka-saitov/#calculator" className="button button__secondary">
                        Калькулятор онлайн
                    </Link>
                    {(props.data.buttonURL) ?
                        <Link className="button button__bordered" to={props.data.buttonURL}>
                            {(props.data.buttonText) ? props.data.buttonText : "Подробнее"}
                        </Link> : ''}
                </div>
            </div>
        </div>
        <div className="container">
            <div className="service__portfolio row">

                {(props.data.portfolio) ?
                    props.data.portfolio.map(el => <ServicePortfolioBlock
                        key={el.id} bgImage={el.bgImage}
                        h3={el.h3}
                        title={el.title} desc={el.desc}
                        logo={el.logo}/>) : ''}

                {(props.data.portfolio) ?
                    <div className="col-md-3">
                        <div className="service__portfolio-elem">
                            <div className="service__portfolio-content service__portfolio-content-more">
                                <Link to="/company/portfolio" className="arrow-link">
                                    <p className="arrow-link__title">Смотреть все работы</p>
                                    <div className="arrow-link__container">
                                        <div className="arrow-link__start"/>
                                        <img
                                            className="arrow-link__icon"
                                            src={eye}
                                            alt="icon"
                                        />
                                        <div className="arrow-link__end">
                                            <div className="arrow-link__tip"/>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div> : ''}

            </div>
        </div>
    </section>
) 
