import React from "react"

class FaqContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false
        }
    }

    clickHandler() {
        (this.state.expand) ? this.setState({expand: false}) : this.setState({expand: true})
    }

    componentDidMount() {
        (this.props.id === "1") ? this.setState({expand: true}) : this.setState({expand: false})
    }

    render() {
        return (
            <div>
                <div
                    className={(this.state.expand) ? 'faq__block-title faq__block-title-expanded' : 'faq__block-title'}
                    onClick={() => this.clickHandler()}>
                    <h3>{this.props.h3}</h3>
                    <div className="faq__block-icon">
                        <div className="faq__block-arrow"/>
                    </div>
                </div>
                <div className="faq__block-container">
                    <p className={(this.state.expand) ? 'faq__block-text faq__block-text-expanded' : 'faq__block-text'}
                       dangerouslySetInnerHTML={{__html: this.props.text}}>
                    </p>
                </div>
            </div>
        )
    }
}

export default FaqContainer