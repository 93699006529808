import React from "react"
import Breadcrumbs from "../../blocks/breadcrumbs";
import H2Title from "../../blocks/h2-title";
import SubmenuBlock from "./submenu-block";

class SubmenuSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section
                className={(this.props.submenuContent.title && this.props.submenuContent.blocks) ?
                    'submenu-section submenu-section-bg_image container-fluid' : 'submenu-section container-fluid'}>
                <div className="container">
                    <Breadcrumbs pageUrl={this.props.pageUrl} breadcrumbs={this.props.breadcrumbs}/>
                    {(this.props.submenuContent.title) ?
                        <div className="row">
                            <div className="col">
                                <H2Title h2={this.props.submenuContent.title}/>
                            </div>
                        </div> : ''}
                    {(this.props.submenuContent.blocks) ?
                        <div className="row">
                            {(this.props.submenuContent.blocks) ? this.props.submenuContent.blocks.map((el, index) =>
                                <SubmenuBlock key={index}
                                              link={el.link || ''}
                                              icon={el.icon || ''}
                                              h4={el.h4 || ''}/>) : ''}
                        </div> : ''}
                </div>
            </section>
        )
    }
}

export default SubmenuSection