import React from "react"

const PaginationDot = (props) => (
    <div className="pagination__dot">
        <input
            type="radio"
            name="pagination"
            id={`pagination__radio-${props.id}`}
        />
        <label
            htmlFor={`pagination__radio-${props.id}`}
            id={`pagination__label-${props.id}`}
        />
    </div>
)

export default PaginationDot