import React from "react"
import H2Title from "../../blocks/h2-title";
import FaqContainer from "./faq-container";
import Helmet from "react-helmet";

class FaqSection extends React.Component {
    constructor(props) {
        super(props);
        this.itemEntitys = [];
        this.props.data.containers.map(el => {
            this.itemEntitys.push({
                "@type": "Question",
                "name": el.h3,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": el.text
                }
            })
        });
        this.schemaOrgFAQPage = {
            "@context": "https://schema.org/",
            "@type": "FAQPage",
            "mainEntity": this.itemEntitys
        };
    }

    render() {
        return (
            <section className="faq container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <H2Title h2={this.props.data.title}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this.props.data.containers.map(el => <FaqContainer key={el.id}
                                                                                id={el.id}
                                                                                h3={el.h3}
                                                                                text={el.text}/>)}
                        </div>
                    </div>
                </div>
                <Helmet script={[
                    {
                        "type": "application/ld+json",
                        innerHTML: `${JSON.stringify(this.schemaOrgFAQPage)}`
                    }
                ]}/>
            </section>
        )
    }
}

export default FaqSection