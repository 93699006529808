import React from "react"
import PaginationDot from "../../blocks/pagination-dot";
import SliderHeader from "./slider-header";
import { Link } from "gatsby"
import Img from "gatsby-image"

class SliderSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0
        };
        this.sliderImages = this.props.data.sliderImages;
        this.sliderHeaderData = this.props.data.sliderHeaderData;
        this.nextSliderImg = this.nextSliderImg.bind(this);
        this.prevSliderImg = this.prevSliderImg.bind(this);

    }

    nextSliderImg() {
        let i = Number((this.state.count < this.sliderImages.length-1) ? this.state.count + 1 : 0);
        this.setState({
            count: i
        });
        document.querySelectorAll('.pagination input')[i].checked = true;
    }

    prevSliderImg() {
        let i = Number((this.state.count < 1) ? this.sliderImages.length - 1 : this.state.count - 1) ;
        this.setState({
            count: i
        });
        document.querySelectorAll('.pagination input')[i].checked = true;
    }

    dotSliderchecked(e) {

        if (e.target.name === 'pagination') {
            let i = Number(e.target.id.slice(-1));
            this.setState({
                count: i
            });
            document.querySelectorAll('.pagination input')[i].checked = true;
        }
    }

    componentDidMount() {
        document.querySelectorAll('.pagination input')[this.state.count].checked = true;
        document.querySelector('.slider__nav-right').addEventListener('click', this.nextSliderImg);
        document.querySelector('.slider__nav-left').addEventListener('click', this.prevSliderImg);
        document.querySelector('.pagination__nav').addEventListener('click', e => this.dotSliderchecked(e));
        this.timer = setInterval(
            () => this.nextSliderImg(),
            3000
        );
    }
    componentWillUnmount() {
        document.querySelector('.slider__nav-right').removeEventListener('click', this.nextSliderImg);
        document.querySelector('.slider__nav-left').removeEventListener('click', this.prevSliderImg);
        document.querySelector('.pagination__nav').removeEventListener('click', this.dotSliderchecked);
        clearInterval(this.timer);
    }

    render() {
        return (
            <section className="slider">
                <SliderHeader h1={this.sliderHeaderData[(this.sliderHeaderData.length !== this.sliderImages.length) ? 0 : this.state.count].h1}
                              p={this.sliderHeaderData[(this.sliderHeaderData.length !== this.sliderImages.length) ? 0 : this.state.count].p}
                              index={(this.sliderHeaderData.length !== this.sliderImages.length) ? 0 : this.state.count}
                />
                <div className="slider__footer">
                    <div>
                        <Link to="/company/" className="button">
                            Сотрудничать
                        </Link>
                        <br/>
                        {/*<a href="#" className="button button__secondary">*/}
                        {/*    Смотреть проект*/}
                        {/*</a>*/}
                    </div>
                    <div className="pagination">
                        <div className="pagination__arrow slider__nav-left">
                            <div className="pagination__arrow-line">
                                <div className="pagination__tip pagination__tip_left"/>
                            </div>
                        </div>
                        <div className="pagination__nav">
                            {this.sliderImages.map( (el, index) => <PaginationDot key={index} id={`slider-${index}`} />)}
                        </div>
                        <div className="pagination__arrow slider__nav-right">
                            <div className="pagination__arrow-line">
                                <div className="pagination__tip pagination__tip_right"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider__image">
                    <div className="slider__image-wrapper">
                        <img className="slider__img" src={this.sliderImages[this.state.count].publicURL} style={{"position": "relative"}} alt="bg"/>
                    </div>
                </div>
            </section>
        )
    }
}


export default SliderSection