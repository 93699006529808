import React from "react"

const CalcInput = (props) => (
    <div>
        <div className="input-amt">
            <input className="input-amt__input"
                   type="text"
                   value={props.value}
                   name={props.name}
                   onChange={(event) => props.handleInputChange(event, props.stepID, props.blockID)}/>
        </div>
        <p className="input-amt__p">{props.description}</p>
    </div>
    )
export default CalcInput