import React from "react"
import H2Title from "../../blocks/h2-title";
import CalcStep from "./calc-step";

class CalcSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            calcData: this.props.data.calcSettings,
            valueName0: '',
            valueName1: '',
            valueName5: '',
            valueName3: '',
            valueName4: '7kP8459f21R8',
            sent: 'not',
        };
        this.blockChecked = this.blockChecked.bind(this);
        this.insideRadioChecked = this.insideRadioChecked.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    handleInputChange(event, step, block) {
        let newValue = (event.target.value > 0) ? Number(event.target.value) : 0;
        let newCalcData = this.state.calcData;
        newCalcData[step].stepBlocks[block].blockInput.value = newValue;
        this.setState({calcData: newCalcData});
        this.calculateTotal();
    }

    blockChecked(step, block) {
        let newCalcData = this.state.calcData;
        if (newCalcData[step].stepBlocks[block].checked === 'checked' && newCalcData[step].stepType !== 'radio') {
            newCalcData[step].stepBlocks[block].checked = '';

        } else if (newCalcData[step].stepBlocks[block].checked !== 'checked' && newCalcData[step].stepType !== 'radio') {
            newCalcData[step].stepBlocks[block].checked = 'checked';

        } else if (newCalcData[step].stepBlocks[block].checked !== 'checked' && newCalcData[step].stepType === 'radio') {
            newCalcData[step].stepBlocks.forEach(el => el.checked = '');
            newCalcData[step].stepBlocks[block].checked = 'checked';
        }
        this.setState({calcData: newCalcData});
        this.calculateTotal();
    }

    insideRadioChecked(step, block, item, click) {
        let newCalcData = this.state.calcData;
        newCalcData[step].stepBlocks[block].radioBlocks.forEach(el => el.checked = '');
        newCalcData[step].stepBlocks[block].radioBlocks[item].checked = 'checked';
        if (click) {
            newCalcData[step].stepBlocks[block].checked = 'checked'
        }
        this.setState({calcData: newCalcData});
        this.calculateTotal();
    }

    calculateTotal() {
        let newCalcData = this.state.calcData;
        let newTotal = 0;
        for (let i = 0; i < newCalcData.length; i++) {
            for (let j = 0; j < newCalcData[i].stepBlocks.length; j++) {
                if (newCalcData[i].stepBlocks[j].checked === 'checked' && !newCalcData[i].stepBlocks[j].radioBlocks) {
                    if (newCalcData[i].stepBlocks[j].blockInput) {
                        newTotal = Number(newTotal) + (Number(newCalcData[i].stepBlocks[j].blockInput.value) * Number(newCalcData[i].stepBlocks[j].blockPrice));
                    } else {
                        newTotal = Number(newTotal) + Number(newCalcData[i].stepBlocks[j].blockPrice);
                    }
                } else if (newCalcData[i].stepBlocks[j].checked === 'checked' && newCalcData[i].stepBlocks[j].radioBlocks) {
                    for (let k = 0; k < newCalcData[i].stepBlocks[j].radioBlocks.length; k++) {
                        if (newCalcData[i].stepBlocks[j].radioBlocks[k].checked === 'checked') {
                            newTotal = Number(newTotal) + Number(newCalcData[i].stepBlocks[j].radioBlocks[k].radioPrice);
                        }
                    }
                }
            }
        }
        this.setState({total: newTotal})
    }

    handleFormChange(event) {
        (event.target.name === 'name-input-0') ? this.setState({valueName0: event.target.value}) :
            (event.target.name === 'name-input-1') ? this.setState({valueName1: event.target.value}) :
                (event.target.name === 'name-input-5') ? this.setState({valueName5: event.target.value}) :
                    (event.target.name === 'name-input-3') ? this.setState({valueName3: event.target.value}) :
                        (event.target.name === 'name-input-4') ? this.setState({valueName4: event.target.value}) :
                            this.setState({});
    }

    handleFormSubmit(event) {
        event.preventDefault();
        if (this.state.valueName0 !== '' && this.state.valueName3 === '' && this.state.valueName4 === '7kP8459f21R8') {
            let dataForm = JSON.stringify({
                formData: {
                    "value0": this.state.valueName0,
                    "value1": this.state.valueName1,
                    "value5": this.state.valueName5,
                    "value3": this.state.valueName3,
                    "value4": this.state.valueName4,
                    "value6": this.state.calcData,
                    "value7": this.state.total
                }
            });

            fetch('/main-form-handler.php', {
                method: "POST",
                headers: {
                    "Content-type": "application/json;charset=utf-8"
                },
                body: dataForm
            })
                .then(response => {
                    response.text()
                })
                .then(data => {
                    console.log("Successful " + data);
                })
                .catch(error => {
                    console.log("error " + error);
                });

            this.setState({
                valueName0: '',
                valueName1: '',
                valueName5: '',
                valueName3: '',
                valueName4: '7kP8459f21R8',
                sent: 'sent'
            });
        } else {
            this.setState({
                sent: 'eff-form'
            });
        }
    }

    componentDidMount() {
        for (let i = 0; i < this.state.calcData.length; i++) {
            if (this.state.calcData[i].stepType === 'radio') {
                this.blockChecked(i, 0)
            }
            for (let j = 0; j < this.state.calcData[i].stepBlocks.length; j++) {
                if (this.state.calcData[i].stepBlocks[j].radioBlocks) {
                    this.insideRadioChecked(i, j, 0)
                }
            }
        }

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <section className="calc container-fluid" id="calculator">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <H2Title h2={this.props.data.title}/>
                        </div>
                    </div>
                    <div className="calc-wrapper">
                        {this.state.calcData.map((el, index) => <CalcStep key={index} id={index} data={el}
                                                                          blockChecked={this.blockChecked}
                                                                          insideRadioChecked={this.insideRadioChecked}
                                                                          handleInputChange={this.handleInputChange}/>)}
                        <div className="total">
                            <p>
                                Итого стоимость: <span id="totalValue">{this.state.total.toString()}</span> Руб.
                            </p>
                        </div>
                        <form className="calc__form" onSubmit={this.handleFormSubmit}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="calc__form_border">
                                        <input
                                            type="text"
                                            className="calc__form_input"
                                            name="name-input-0"
                                            placeholder="Ваше имя"
                                            value={this.state.valueName0}
                                            onChange={this.handleFormChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="calc__form_border">
                                        <input
                                            type="phone"
                                            className="calc__form_input"
                                            name="name-input-1"
                                            placeholder="Ваш телефон"
                                            value={this.state.valueName1}
                                            onChange={this.handleFormChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="calc__form_border">
                                        <textarea
                                            className="calc__form_input"
                                            name="name-input-5"
                                            rows={1}
                                            placeholder="дополнительные пожелания"
                                            value={this.state.valueName5}
                                            onChange={this.handleFormChange}
                                        />
                                    </div>
                                </div>
                                <div className="d-none">
                                    <div>
                                        <input
                                            className="form-section__input"
                                            type="text"
                                            name="name-input-8"
                                            value={this.state.valueName3}
                                            onChange={this.handleFormChange}
                                        />
                                    </div>
                                </div>
                                <div className="d-none">
                                    <div>
                                        <input
                                            className="form-section__input"
                                            type="text"
                                            name="name-input-9"
                                            value={this.state.valueName4}
                                            onChange={this.handleFormChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="calc-promo row">
                                {(this.state.sent === 'sent') ?
                                    <p style={{color: 'black'}}>Ваше сообщение успешно отправлено</p> :
                                    (this.state.sent === 'eff-form') ?
                                        <p style={{color: 'black'}}>Не заполнена форма</p> :
                                        <div className="col-md-12">
                                            <button type="submit" className="button button__secondary">Заказать</button>
                                            <p className="calc-promo"
                                               dangerouslySetInnerHTML={{__html: this.props.data.promoText}}/>
                                        </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}

export default CalcSection