import React from "react"
import TariffsColumn from "./tariffs-column";
import FormSection from "../form-section/form-section";


class TariffsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkColumns: this.props.data.columns.map((el) => el.checked)
        };
        this.tariffsWindow = React.createRef();
        this.tariffsWrap = React.createRef();
        this.itemHover = this.itemHover.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
    }

    modalOpen() {
        this.props.modalOpen(<FormSection data={this.props.data.modal}/>, 'white')
    }

    itemHover(e, id) {
        if (!e.currentTarget.classList.contains('tariffs__column-checked')) {
            let newState = this.state.checkColumns;
            this.setState({checkColumns: newState.map((el,index) => index === id)})
        }
    }

    componentDidMount() {
        let tariffsWrapStyles = window.getComputedStyle(this.tariffsWrap.current);
        let columnWidth = (Number(tariffsWrapStyles.width.slice(0, -2)) / this.state.checkColumns.length);
        let positionOfCheckingElement = this.state.checkColumns.indexOf(true);
        this.tariffsWindow.current.scrollLeft = columnWidth / 2 + columnWidth * positionOfCheckingElement - window.innerWidth / 2;
        this.tariffsWindow.current.style.height = `${Number(tariffsWrapStyles.height.slice(0, -2)) * 1.2}px`
    }

    render() {
        return (
            <section className="tariffs">
                <div className="container">
                    <div className="title">
                        <div className="title__line"/>
                        <h2>{this.props.data.title}</h2>
                    </div>
                </div>
                <div className="tariffs__window container" ref={this.tariffsWindow}>
                    <div className="tariffs__wrap" ref={this.tariffsWrap}>
                        {this.props.data.columns.map((el, index) => <TariffsColumn key={index} data={el}
                                                                                   check={this.state.checkColumns[index]}
                                                                                   itemHover={this.itemHover}
                                                                                   id={index}
                                                                                   columns={this.props.data.columns.length}
                                                                                   modalOpen={this.modalOpen}
                        />)}
                    </div>
                </div>
            </section>
        )
    }
}

export default TariffsSection